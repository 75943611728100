* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif;
}

body {
	text-align: center;
	background-color: #1b2034;
	color: #fff;
}

img {
	display: block;
	width: 480px;
	height: 480px;
	margin: 2rem auto;
}

.app {
	padding: 2rem;
}

h1 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

input {
	appearance: none;
	outline: none;
	border: none;
	background: #EEE;
	width: 100%;
	max-width: 520px;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
}

a {
	appearance: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;

	color: #2bcb4b;
	font-size: 1.5rem;
	text-decoration: none;
}


.button {
	background-color: #04AA6D; 
	border: none;
	color: white;
	padding: 16px 27px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	transition-duration: 0.4s;
	cursor: pointer;
  }
  
  .button1 {
	background-color: #008CBA; 
	color: black; 
	border: 2px solid #008CBA;
  }
  
  .button1:hover {
	background-color: #04AA6D;
	color: white;
	  border: 2px solid #04AA6D;
  }



  .dropdown {
	width: 500px;
	margin: 50px auto;
  }
  
  #dropdown-select {
	width: 100%;
	padding: 10px;
	font-size: 14px;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
  }
  
  #other-text {
	margin-top: 10px;
	width: calc(100% - 22px); /* Adjust for padding and border */
	padding: 10px;
	font-size: 14px;
   color : blue;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
  }
  
  .hidden {
	display: none;
  }
  


  .upperSide {
	padding: 3rem;
	border-bottom: 2px solid rgb(100,100,100);
	height: 80%;
  }
  
  .upperSideTop {
	display:flex;
	align-items: center;
	margin-bottom: 3rem;
	}


  /* Footer.css */

.footer {
	background-color: #333;
	color: #fff;
	padding: 20px;
	text-align: center;
	position: fixed;
	bottom: 0;
	width: 100%;
  }
  
  .footer-content {
	max-width: 960px;
	margin: 0 auto;
  }
  
